import React, { useEffect, useState } from 'react';
import './prop-items.styles.scss';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faLock, faXmark } from '@fortawesome/free-solid-svg-icons';
import check_box  from '@/assets/check-box.png';
import crossed from '@/assets/crossed.png'
import { formatNumber } from '@/utils/formatNumber';
import { getPercentages } from '../matchup-list/matchup-item.component';

function getTotals(picks) {
    if (!picks) return [0,0];
    const c1 = (Object.values(picks).filter(p => p.pick === 'comp1').reduce((acc, p) => { return acc + p.wager }, 0)) 
    const c2 = (Object.values(picks).filter(p => p.pick === 'comp2').reduce((acc, p) => { return acc + p.wager }, 0))
    return [formatNumber(c1), formatNumber(c2)];
}

export const PropItem = ({ 
    prop,
    matchups
}) => {

    const userProfile = useSelector(state => state.userData.profile);
    const [ pick, setPick ] = useState({});
    const [ pcts, setPcts ] = useState({}); 
    const [ matchup, setMatchup ] = useState({})
    const [ totalWagers, setTotalWagers ] = useState(0);

    useEffect(() => {
        if (!prop) return;
        let percentages = getPercentages(prop.picks, prop);
        setPcts(percentages);
        const arr = Object.keys(percentages);
        let total = arr.reduce((acc, key) => {
            if (key.includes('c')) return acc;
            return acc + percentages[key];
        }, 0);
        setTotalWagers(total);
        if (prop.picks && prop.picks[userProfile.appId]) {
            setPick(prop.picks[userProfile.appId]);
        } else {
            setPick({})
        }
    }, [prop])

    useEffect(() => {
        if (!prop || !matchups) return;
        let match = matchups[prop.matchup];
        setMatchup(match);
    }, [prop, matchups])

    return (
        <div className='g-box prop-item'>
            <div className='desc-div'>
                <div className="desc-title">
                    {prop.name}
                </div>
                <div className="desc-desc">
                    {prop.desc}
                </div>
                <div className="g-space-0-5"></div>
                {
                    (prop.date > new Date().getTime())
                    ?
                    <div className='date'>Locks: {format(new Date(prop.date), "E, Pp")}</div>
                    :
                    <div className='date'><FontAwesomeIcon icon={faLock} /></div>
                }
                
                {
                    (matchup) &&
                    <div className='matchup-div'>
                        <div className='matchup-img'>
                            <img src={matchup.comp1Icon} />
                        </div>
                        <div>{matchup.comp1}</div>
                        <div>vs</div>
                        <div>{matchup.comp2}</div>
                        <div className='matchup-img'>
                            <img src={matchup.comp2Icon} />
                        </div>
                    </div>
                }
                <div className="g-space-0-5"></div>
                {
                    (prop.winner && pick.pick) &&
                    <div className='result'>
                        Result: {
                            pick.pick === prop.winner 
                            ?
                            <span className='win'>+{pick.wager}</span>
                            : 
                            <span className='loss'>-{pick.wager}</span>
                        }
                    </div>
                }
                <div className="g-space-0-5"></div>
                <div className='details'>
                    {/* <div className={`pick-box-div`}>
                        <div className={`pick-box ${pick.pick === 'comp1' ? 'picked' : ''}`}>
                            <div>{prop.comp1}</div>
                            
                        </div>
                    </div>
                    <div className={`pick-box-div`}>
                        <div className={`pick-box ${pick.pick === 'comp2' ? 'picked' : ''}`}>
                            <div>{prop.comp2}</div>
                            
                        </div>
                    </div> */}
                    {
                        (prop.winner || pick.pick)
                        ?
                        [1,2,3,4,5].map(e => {
                            const path = `comp${e}`;
                            if (!prop[path]) return;
                            let pct = pcts && pcts[`c${e}`];
                            let optTotal = Object.values(pcts).length / 2;
                            let leading = pct >= 1/optTotal;
                            if (prop.winner) {
                                return (
                                    <div 
                                        key={e}
                                        className={`
                                            g-list-item 
                                            ${
                                                (
                                                    prop.winner === path &&
                                                    pick.pick === path
                                                )
                                                ? 'picked-correct'
                                                : 
                                                (
                                                    pick.pick === path
                                                )
                                                ? 'picked-wrong'
                                                : ''
                                            } 
                                            ${
                                                prop.winner === path 
                                                ? 'winner'
                                                : ''
                                            }`}>
                                        <div>{prop[path]}</div>
                                        
                                        <div 
                                            className={
                                                `meta with-check ${leading ? 'green' : 'red'}`
                                            } >
                                            {
                                                (prop.winner === path) 
                                                ?
                                                <FontAwesomeIcon icon={faCheck} className='green' />
                                                :
                                                null
                                            }
                                            {`${pct}%`}
                                        </div>
                                    </div>
                                )
                            }
                            else if (pick && prop.date < new Date().getTime()) {
                                return (
                                    <div 
                                        key={e}
                                        className={`
                                            g-list-item 
                                            ${
                                                (
                                                    pick.pick === path
                                                )
                                                ? 'picked'
                                                : ''
                                            } 
                                            `}>
                                        <div>{prop[path]}</div>
                                        
                                        <div 
                                            className={
                                                `meta ${leading ? 'green' : 'red'}`
                                            } >
                                            {`${pct}%`}
                                        </div>
                                    </div>
                                )
                            }
                            else if (pick) {
                                if (pick.pick !== path) return;
                                return (
                                    <div key={e}>
                                        <div className='center-col'>
                                            <b>My Pick</b>
                                        </div>
                                        <div className="g-space-0-5"></div>
                                        <div key={e} className={`g-list-item ${pick.pick === path ? 'picked' : ''}`}>
                                            <div>{prop[path]}</div>
                                            <div className={
                                                `meta ${leading ? 'green' : 'red'}`
                                            }>{
                                                `${pct}%`
                                            }</div>
                                        </div>
                                    </div>
                                )
                            } 
                        })
                        :
                        <div className='center-col'>
                        <button className='g-button'>
                            Make Your Pick
                        </button>
                        </div>
                    }
                </div>
                {
                    (prop.comp1Details) &&
                    <>
                    <div className='g-space-0-5'></div>
                    <div className='meta'>{prop.comp1Details}</div>
                    </>
                }
                <div className="g-space-1"></div>
                {
                    (!prop.winner && pick.wager) &&
                    <div><b>My Wager: {pick.wager}</b></div>
                }
                
                <div className='wagers'>
                    <div className='meta'>Total Wagers: {formatNumber(totalWagers)}</div>
                </div>
                
                
            </div>
        </div>
    )
}

export const CompletePropItem = ({ 
    prop,
    matchups
}) => {

    const userProfile = useSelector(state => state.userData.profile);
    const [ pick, setPick ] = useState({});
    const [ pcts, setPcts ] = useState({}); 
    const [ matchup, setMatchup ] = useState({})
    const [ totalWagers, setTotalWagers ] = useState(0);

    useEffect(() => {
        if (!prop) return;
        let percentages = getPercentages(prop.picks, prop);
        setPcts(percentages);
        const arr = Object.keys(percentages);
        let total = arr.reduce((acc, key) => {
            if (key.includes('c')) return acc;
            return acc + percentages[key];
        }, 0);
        setTotalWagers(total);
        if (!prop.picks) return;
        if (prop.picks[userProfile.appId]) {
            setPick(prop.picks[userProfile.appId]);
        } 
    }, [prop])

    useEffect(() => {
        if (!prop || !matchups) return;
        let match = matchups[prop.matchup];
        setMatchup(match);
    }, [prop, matchups])

    // useEffect(() => {
    //     if (!pick || !pick.pick || !prop) return;
    //     if (prop.winner) {
    //         if (pick.pick === prop.winner) {
    //             if (prop.winner === 'comp1') {
    //                 setPick1Bg('picked-correct');
    //                 setPick2Bg('');
    //             } else {
    //                 setPick1Bg('');
    //                 setPick2Bg('picked-correct');
    //             }
    //         } else {
    //             if (prop.winner === 'comp1') {
    //                 setPick1Bg('');
    //                 setPick2Bg('picked-wrong');
    //             } else {
    //                 setPick1Bg('picked-wrong');
    //                 setPick2Bg('');
    //             }
    //         }
    //     } else if (pick.pick === 'comp1') {
    //         setPick1Bg('picked');
    //         setPick2Bg('');
    //     } else if (pick.pick === 'comp2'){
    //         setPick1Bg('');
    //         setPick2Bg('picked');
    //     }
    // }, [pick, prop])

    return (
        <div className='g-box prop-item passed'>
            <div className='desc-div'>
                <div className="desc-title">
                    {prop.name}
                </div>
                <div className="desc-desc">
                    {prop.desc}
                </div>
                {
                    (new Date().getTime() < prop.date) 
                    ?
                    <div>Locks: {format(new Date(prop.date), "E, Pp")}</div>
                    :
                    <div className='center-col'><FontAwesomeIcon icon={faLock} /></div>
                } 
                {
                    (prop.winner && pick.pick) 
                    ?
                    <div className='result'>Result: {pick.pick === prop.winner ? <span className='win'>+{pick.wager}</span> : <span className='loss'>-{pick.wager}</span>}</div>
                    :
                    (pick.wager) &&
                    <div><b>Wager: {pick.wager}</b></div>
                }
                <div className='details'>
                    <div className={`pick-box-div`}>
                        <div className={`pick-box ${pick1Bg}`}>
                            {prop.comp1}
                            {
                                (prop.winner === 'comp1' && !pick.pick) 
                                ?
                                <>
                                    <div className="g-space-0-5"></div>
                                    <img className='check' src={check_box} />
                                </>
                                :
                                (prop.winner === 'comp2' && !pick.pick) 
                                ?
                                <>
                                    <div className="g-space-0-5"></div>
                                    <img className='crossed' src={crossed} />
                                </>
                                :
                                ''
                            }
                        </div>
                    </div>
                    <div className={`pick-box-div`}>
                        <div className={`pick-box ${pick2Bg}`}>
                            {prop.comp2}
                            {
                                (prop.winner === 'comp2' && !pick.pick) 
                                ?
                                <>
                                    <div className="g-space-0-5"></div>
                                    <img className='check' src={check_box} />
                                </>
                                :
                                (prop.winner === 'comp1' && !pick.pick) 
                                ?
                                <>
                                    <div className="g-space-0-5"></div>
                                    <img className='crossed' src={crossed} />
                                </>
                                :
                                ''
                            }
                        </div>
                    </div>
                    <div className='meta'>Tot Wagers: {getTotals(prop.picks)[0]}</div>
                    <div className='meta'>Tot Wagers: {getTotals(prop.picks)[1]}</div>
                </div>
               
            </div>
        </div>
    )
}