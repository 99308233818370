import React, { useEffect, useRef, useState } from 'react';
import './prop-pick-modal.styles.scss';
import Modal from '@/components/modal/modal.component';
import { format } from 'date-fns';
import getWagers from '@/utils/wager.utils';
import question_mark from '@/assets/questionmark.png';
import streak_bonus from '@/assets/streak-bonus.png';
import { useSelector } from 'react-redux';
import { UseCloudFunction } from '@/firebase-utils/firebase.utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faLock } from '@fortawesome/free-solid-svg-icons';
import check_box  from '@/assets/check-box.png';
import crossed from '@/assets/crossed.png'
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import { toast } from 'react-toastify';
import { properCase } from '@/utils/properCase';
import UIkit from 'uikit';
import { getPercentages } from '../matchup-list/matchup-item.component';
import { formatNumber } from '@/utils/formatNumber';
import MatchupUserList from '../matchup-list/matchup-user-list.component';

const PropPickModal = ({ 
    closeModal, 
    prop,
    matchups,
    leaders
}) => {

    const userProfile = useSelector(state => state.userData.profile);
    const currentGame = useSelector(state => state.gameData.currentGame);
    const [ pick, setPick ] = useState({});
    const [ pickedWager, setPickedWager ] = useState('');
    const [ loading, setLoading ] = useState(false);
    const [ formData, setFormData ] = useState({});
    const [ comp1Total, setComp1Total ] = useState(0);
    const [ comp2Total, setComp2Total ] = useState(0);
    const [ pick1Bg, setPick1Bg ] = useState('');
    const [ pick2Bg, setPick2Bg ] = useState('');
    const [ matchup, setMatchup ] = useState(null);
    const [ pcts, setPcts ] = useState({});
    const [ totalWagers, setTotalWagers ] = useState(0);
    const [ removeLoading, setRemoveLoading ] = useState(false);
    const dataRef = useRef();

    useEffect(() => {
        if (!prop) return;
        console.log(prop);
        let percentages = getPercentages(prop.picks, prop);
        setPcts(percentages);
        const arr = Object.keys(percentages);
        let total = arr.reduce((acc, key) => {
            if (key.includes('c')) return acc;
            return acc + percentages[key];
        }, 0);
        setTotalWagers(total);
        if (prop.picks && prop.picks[userProfile.appId]) {
            setPick(prop.picks[userProfile.appId]);
            setPickedWager(prop.picks[userProfile.appId].wager);
            dataRef.current = {
                ...dataRef.current, 
                ...{
                    'wager': prop.picks[userProfile.appId].wager,
                    'pick': prop.picks[userProfile.appId].pick,
                }
            }
            setFormData(dataRef.current);
        } else {
            setPick({});
            setPickedWager('');
        }    
    }, [prop])

    // useEffect(() => {
    //     if (!prop || !prop.picks || !userProfile) return;
    //     if (prop.picks[userProfile.appId]) {
    //         setPick(prop.picks[userProfile.appId]);
    //         setPickedWager(prop.picks[userProfile.appId].wager);
    //         dataRef.current = {
    //             ...dataRef.current, 
    //             ...{
    //                 'wager': prop.picks[userProfile.appId].wager,
    //                 'pick': prop.picks[userProfile.appId].pick,
    //             }
    //         }
    //         setFormData(dataRef.current);
    //     }

    //     const c1 = (Object.values(prop.picks).filter(p => p.pick === 'comp1').reduce((acc, p) => { return acc + p.wager }, 0))
    //     setComp1Total(formatNumber(c1)); 
    //     const c2 = (Object.values(prop.picks).filter(p => p.pick === 'comp2').reduce((acc, p) => { return acc + p.wager }, 0))
    //     setComp2Total(formatNumber(c2));

    //     function formatNumber(number) {
    //         // Define the suffixes for thousand, million, billion, etc.
    //         const suffixes = ['', 'k', 'M', 'B', 'T'];
    //         // Divide the number by 1000 until it's less than 1000 and find the appropriate suffix
    //         let suffixIndex = 0;
    //         while (number >= 1000 && suffixIndex < suffixes.length - 1) {
    //             number /= 1000;
    //             suffixIndex++;
    //         }
    //         // Round the number to 1 decimal place
    //         number = Math.round(number * 10) / 10;
    //         // Append the suffix
    //         const formattedNumber = number.toString() + suffixes[suffixIndex];
    //         return formattedNumber;
    //     }

    //     return () => {
    //         setPick1Bg('');
    //         setPick2Bg('');
    //     }

    // }, [prop, userProfile])

    useEffect(() => {
        if (!prop || !matchups) return;
        setMatchup({...matchups[prop.matchup]});
    }, [prop, matchups])

    useEffect(() => {
        if (!pick || !pick.pick || !prop) return;
        if (prop.winner) {
            if (pick.pick === prop.winner) {
                if (prop.winner === 'comp1') {
                    setPick1Bg('picked-correct');
                    setPick2Bg('');
                } else if (prop.winner === 'comp2') {
                    setPick1Bg('');
                    setPick2Bg('picked-correct');
                }
            } else if (pick.pick) {
                if (prop.winner === 'comp1') {
                    setPick1Bg('');
                    setPick2Bg('picked-wrong');
                } else if (prop.winner === 'comp2') {
                    setPick1Bg('picked-wrong');
                    setPick2Bg('');
                }
            }
        } else if (pick.pick === 'comp1') {
            setPick1Bg('picked');
            setPick2Bg('');
        } else if (pick.pick === 'comp2'){
            setPick1Bg('');
            setPick2Bg('picked');
        }
    }, [pick, prop])

    function cancelPick() {
        dataRef.current = {...{}}
        setFormData(dataRef.current);
        setPick({...{}});
        closeModal();
        setComp1Total(0);
        setComp2Total(0);
        setLoading(false);
        setPickedWager('');
    }

    function saveData(key, value) {
        if (key === 'pick' && (!dataRef.current || !dataRef.current.wager)) {
            UIkit.modal.alert('You must make a wager before picking a winner.');
            return;
        }
        if (key === 'wager' && !value) {
            UIkit.modal.alert('You must make a wager before picking a winner.');
            return;
        }
        dataRef.current = {...dataRef.current, ...{[key]: value}}
        setFormData(dataRef.current);
        if (key === 'pick') {
            savePick('pick');
        }
        if (key === 'wager') {
            setPickedWager(value);
            if (dataRef.current.pick) {
                savePick('wager');
            }
        }
    }   

    async function savePick(pickWager) {
        
        if (pickWager === 'pick') {
            setLoading(true);
        }
        const res = await UseCloudFunction(
            'savePick', 
            {
                'gameId': currentGame.gameId,
                'matchupId': prop.id,
                'pick': dataRef.current.pick,
                'wager': dataRef.current.wager,
                'appId': userProfile.appId,
                'ts': new Date().getTime(),
            }
        )
        console.log(res);
        toast(`${properCase(pickWager)} saved successfully!`);
        if (pickWager === 'pick') {
            cancelPick();
        }
    }

    async function removePick() {
        setRemoveLoading(true);
        const res = await UseCloudFunction('removePick', {
            'matchupId': prop.id,
            'appId': userProfile.appId,
        })
        console.log(res);
        setRemoveLoading(false);
        closeModal();
    }

    return (
        <div className='pick-modal'>
            <Modal 
                handleClose={cancelPick} 
                cancel={cancelPick} 
                show={prop} 
                scrolling='modal-scroll'
                buttonText={'Save Wager'} >
                {
                    (prop) &&
                    <div className='modal-content'>
                        <div className='title'>{prop.name}</div>
                        <div className='desc'>{prop.desc}</div>
                        <div className="g-space-0-5"></div>
                        {
                            (new Date().getTime() < prop.date) 
                            ?
                            <div className='lock-date'>Locks: {format(new Date(prop.date), "E, Pp")}</div>
                            :
                            <div className='center-col'><FontAwesomeIcon icon={faLock} /></div>
                        } 
                        {
                            (matchup) &&
                            <div className='matchup-div'>
                                <div className='matchup-img'>
                                    <img src={matchup.comp1Icon} />
                                </div>
                                <div>{matchup.comp1}</div>
                                <div>vs</div>
                                <div>{matchup.comp2}</div>
                                <div className='matchup-img'>
                                    <img src={matchup.comp2Icon} />
                                </div>
                            </div>
                        }
                        <div className="g-space-1"></div>
                        {
                            ((new Date().getTime() < prop.date) && !prop.winner)
                            ? 
                            <div className='field'>
                                <label><b>Pick a Wager</b></label><br/>
                                <select 
                                    className="uk-select uk-form-small uk-form-width-small wager" 
                                    value={pickedWager}
                                    onChange={e => saveData('wager', Number(e.target.value))}  >	
                                {getWagers(prop.wager, prop.id)}
                                </select>
                            </div>
                            : 
                            (prop.winner && pick.pick)
                            ?
                            <div>
                                <b>Result: {pick.pick === prop.winner ? <span className='win'>+{pick.wager}</span> : <span className='loss'>-{pick.wager}</span>}</b>
                            </div>
                            :
                            <div>
                                {
                                    (pick.wager) &&
                                    <b>Wager: {pick.wager}</b>
                                }
                                
                                
                            </div>
                        }
                        
                        <div className="g-space-1"></div>
                        <div className='choices-div'>
                        {
                            [1,2,3,4,5].map(e => {
                                const path = `comp${e}`;
                                if (!prop[path]) return;
                                let pct = pcts && pcts[`c${e}`];
                                let optTotal = Object.values(pcts).length / 2;
                                let leading = pct >= 1/optTotal;
                                console.log(leading);
                                if (prop.winner) {
                                    return (
                                        <div 
                                            key={e}
                                            className={`
                                                g-list-item 
                                                ${
                                                    (
                                                        prop.winner === path &&
                                                        pick.pick === path
                                                    )
                                                    ? 'picked-correct'
                                                    : 
                                                    (
                                                        pick.pick === path
                                                    )
                                                    ? 'picked-wrong'
                                                    : ''
                                                } 
                                                ${
                                                    prop.winner === path 
                                                    ? 'winner'
                                                    : ''
                                                }`}>
                                            <div>{prop[path]}</div>
                                            
                                            <div 
                                                className={
                                                    `meta with-check ${leading ? 'green' : 'red'}`
                                                } >
                                                {
                                                    (prop.winner === path) 
                                                    ?
                                                    <FontAwesomeIcon icon={faCheck} className='green' />
                                                    :
                                                    null
                                                }
                                                {`${pct}%`}
                                            </div>
                                        </div>
                                    )
                                }
                                else {
                                    return (
                                        <button 
                                            key={e} 
                                            className={`
                                                g-button 
                                                pick-button 
                                                ${pick.pick === path 
                                                    ? 'picked' 
                                                    : ''
                                                } 
                                                ${
                                                    (new Date().getTime() >= prop.date) 
                                                    ? 'disabled'
                                                    : ''
                                                }
                                            `}
                                            onClick={() => 
                                                (new Date().getTime() < prop.date) 
                                                ? saveData('pick', path)
                                                : null
                                            } >
                                            <div>{prop[path]}</div>
                                            <div className={
                                                `meta ${leading ? 'green' : 'red'}`
                                            }>{
                                                `${pct}%`
                                            }</div>
                                        </button>
                                    )
                                }
                            })
                        }
							{/* <div className={`modal-img-div ${pick1Bg}`}>
								<div >{prop.comp1}</div>
                                {
                                    (loading)
                                    ?
                                    <button className='g-button' ><ThreeDotsLoader /></button>
                                    :
                                    (new Date().getTime() < prop.date && prop.comp1)
                                    ? <button className='g-button' onClick={() => saveData('pick','comp1')}>Pick</button>
                                    : ''
                                }
                                {
                                    (prop.winner === 'comp1' && !pick.pick) 
                                    ?
                                    <>
                                        <div className="g-space-0-5"></div>
                                        <img className='check' src={check_box} />
                                    </>
                                    :
                                    (prop.winner === 'comp2' && !pick.pick) 
                                    ?
                                    <>
                                        <div className="g-space-0-5"></div>
                                        <img className='crossed' src={crossed} />
                                    </>
                                    :
                                    ''
                                }
							</div>
							
							<div className={`modal-img-div ${pick2Bg}`}>
								<div>{prop.comp2}</div>
                                {
                                    (loading)
                                    ?
                                    <button className='g-button' ><ThreeDotsLoader /></button>
                                    :
                                    (new Date().getTime() < prop.date && prop.comp1)
                                    ? <button className='g-button' onClick={() => saveData('pick','comp2')}>Pick</button>
                                    : ''
                                }
                                {
                                    (prop.winner === 'comp2' && !pick.pick) 
                                    ?
                                    <>
                                        <div className="g-space-0-5"></div>
                                        <img className='check' src={check_box} />
                                    </>
                                    :
                                    (prop.winner === 'comp1' && !pick.pick) 
                                    ?
                                    <>
                                        <div className="g-space-0-5"></div>
                                        <img className='crossed' src={crossed} />
                                    </>
                                    :
                                    ''
                                }
							</div> */}
                        </div>
                        <div className="g-space-1"></div>
                        <div className='wager-info'>
                            <div className={`meta`}>
                                Total Wagers: {formatNumber(totalWagers)}
                            </div>
						</div>
                        {
                            (prop.comp1Details) &&
                            <>
                                <div className='g-space-0-5'></div>
                                <div className='comp-details'>{prop.comp1Details}</div>
                            </>
                        }
                        {
                            (pick && prop.date > new Date().getTime()) &&
                            <div className='center-col'>
                                {   
                                    (removeLoading)
                                    ?
                                    <button className='g-button small-button remove'>
                                        <ThreeDotsLoader />
                                    </button> 
                                    :
                                    <button 
                                        className='g-button no-button remove'
                                        onClick={() => removePick()}>
                                        Remove Pick
                                    </button>
                                }
                            </div>
                        }
                        {
                            (prop.winner || prop.date < new Date().getTime()) &&
                            <div className='user-pick-list'>
                                <div className='g-space-1'></div>
                                <hr />
                                <div className='g-space-1'></div>
                                <b>Player Picks</b>
                                <div className='g-space-1'></div>
                                <MatchupUserList
                                    matchup={prop}
                                    leaders={leaders} />
                            </div>
                        }
                    </div>
                }
            </Modal>
        </div>
    )
}

export default PropPickModal