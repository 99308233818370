import React, { useEffect, useState } from 'react';
import './props-list-container.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay, faCoins, faLock } from '@fortawesome/free-solid-svg-icons';
import { makeLinkActive } from '@/utils/active-links';
import { CompletePropItem, PropItem } from './prop-items.component';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import PropPickModal from './prop-pick-modal.component';

const PropsListContainer = ({ 
    matchups,
    leaders 
}) => {

    const [ viewing, setViewing ] = useState('open');
    const [ viewHeight, setViewHeight ] = useState(0);
    const [ time, setTime ] = useState(new Date().getTime());
    const [ chosenProp, setChosenProp ] = useState(null);
    const [ open, setOpen ] = useState(null);
    const [ locked, setLocked ] = useState(null);
    const [ loading, setLoading ] = useState(true);
    const [ complete, setComplete ] = useState(null);

    useEffect(() => {
        makeLinkActive('props-link');
        
        const frontContainer = document.getElementsByClassName('props-div');
        if (frontContainer.length > 0) {
            setViewHeight(`${frontContainer[0].clientHeight - 115}px`)
            console.log(frontContainer[0].clientHeight);
        }
		
	}, [])

    useEffect(() => {
        if (!matchups) return;
        console.log(matchups);
        sortMatchups();
		const checkTime = setInterval(() => {
			sortMatchups();
		}, 300000)
        return () => {
			clearInterval(checkTime)
		}
    }, [matchups])

    function sortMatchups() {
        let openArr = []
        let completeArr = []
        let lockedArr = []
        const now = new Date().getTime();
        for (let m of Object.values(matchups).filter(m => m.type === 'prop')) {
            if (m.date > now) {  //+ 10800000
                openArr.push(m)
            } else if (!m.winner) {
                lockedArr.push(m)
            } else {
                completeArr.push(m)
            }
        }
        setOpen([...openArr]);
        setComplete([...completeArr]);
        setLocked([...lockedArr]);
        setLoading(false);
    }

    return (
        <div className='props-list-container page'>
            <div className='g-box head-box '>
				<span className='main'>
					<FontAwesomeIcon icon={faCoins} className='head-icon' />
				    Prop Bets
				</span>
				<hr />
                <div className='top-buttons'>
                    <button className={`g-button medium-btn ${viewing === 'open' ? '' : 'inactive'}`} onClick={() => setViewing('open')}>Open</button>
                    <button className={`g-button medium-btn ${viewing === 'locked' ? '' : 'inactive'}`} onClick={() => setViewing('locked')}><FontAwesomeIcon icon={faLock} /></button>
                    <button className={`g-button medium-btn ${viewing === 'complete' ? '' : 'inactive'}`} onClick={() => setViewing('complete')}>Complete</button>
                </div>
                {
                    (loading)
                    ?
                    <div className='center-col'>
                        <ThreeDotsLoader />
                    </div>
                    :
                    <div className={`props-list-content`} style={{'height': viewHeight ? viewHeight : ''}}>
                    {
                        (viewing === 'open')
                        ?
                        (open && open.length > 0)
                        ?
                        open.sort((a,b) => a.date.toString().localeCompare(b.date.toString()) ||
                        a.name.localeCompare(b.name))
                        .map(m => (
                            <div 
                                key={m.id} 
                                className='clickable'
                                onClick={() => setChosenProp(m)}>
                                <PropItem 
                                    prop={m}
                                    matchups={matchups} />
                            </div>
                        ))
                        :
                        <h5>No open props...</h5>
                        :
                        (viewing === 'locked')
                        ?
                        (locked && locked.length > 0)
                        ?
                        locked.sort((a,b) => a.date.toString().localeCompare(b.date.toString()) ||
                        a.name.localeCompare(b.name))
                        .map(m => (
                            <div 
                                key={m.id}
                                className='clickable'
                                onClick={() => setChosenProp(m)} >
                                <PropItem 
                                    prop={m}
                                    matchups={matchups} />
                            </div>
                        ))
                        :
                        <h5>No locked props...</h5>
                        :
                        (complete.length > 0)
                        ?
                        complete.sort((a,b) => a.ts < b.ts ? 1 : a.ts === b.ts && a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1)
                        .map(m => (
                            <div 
                                key={m.id} 
                                className='clickable'
                                onClick={() => setChosenProp(m)} >
                                <PropItem 
                                    prop={m}
                                    matchups={matchups} />
                            </div>
                        ))
                        :
                        <h5>No completed props...</h5>
                    }
                    </div>
                }
            </div>
            <PropPickModal 
                prop={chosenProp} 
                matchups={matchups}
                leaders={leaders}
                closeModal={() => setChosenProp(null)} />
        </div>
    )
}

export default PropsListContainer