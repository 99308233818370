import React, { useEffect, useRef, useState } from 'react'
import './game-page.styles.scss'
import { useSelector, useDispatch } from 'react-redux'
import { Routes, Route, useNavigate, Link } from 'react-router-dom'

import SideNav from '../../components/side-nav/side-nav.component'
import UserInfoCol from '../../components/user-info-col/user-info-col.component'
import MobileNav from '../../components/mobile-nav/mobile-nav.component'
import SlidingUserInfo from '../../components/sliding-user-info/sliding-user-info.component'
import GameLanding from '../../components/game-landing/game-landing.component'

import UIkit from 'uikit'
import { getCurrentToken, UseCloudFunction, db as fbDb } from '@/firebase-utils/firebase.utils'
import { revertAll, setMyTokens, setSavedToken } from '@/state/slices/userSlice'
import GameLanding2 from '@/components/game-landing/game-landing-2.component'
import SideNav2 from '@/components/side-nav/side-nav-2.component'
import NewResultsModal from '@/components/game-landing/new-results-modal.component'
import { off } from 'firebase/database'
import { getDataFromRtDb, getMatchupsFromDb, getQueryFromDb, getUniqueDocFromDb } from '@/utils/getDataFromDb'
import { setCurrentGame } from '@/state/slices/gameSlice'
import NewMessageModal from '@/components/game-landing/new-message-modal.component'

const GamePage2 = () => {
	
    // OLD
    const currentToken = useSelector(state => state.userData.currentToken)
    const myTokens = useSelector(state => state.userData.myTokens)
	const dispatch = useDispatch()
    
    const currentGame = useSelector(state => state.gameData.currentGame)
	const userProfile = useSelector(state => state.userData.profile)
    const [ hof, setHof ] = useState(null);
    const [ matchups, setMatchups ] = useState(null);
    const [ leaders, setLeaders ] = useState(null);
    const [ chats, setChats ] = useState(null);
    const [ notifications, setNotifications ] = useState(null);
    const [ unread, setUnread ] = useState(null);
    const [ gameProfile, setGameProfile ] = useState(null);
    const matchupsRef = useRef();
    const chatsRef = useRef();
    const notificationsRef = useRef();
    let matchupsListener;
    let leadersListener;
    let picksListener;
    let chatsListener;
    let notificationsListener;
    let unreadListener;
	
	const navigate = useNavigate()

    useEffect(() => {
        setTimeout(() => {
            if (!matchupsRef.current) {
                setMatchups('none');
            }
        }, 4000)

        return () => {
            if (matchupsListener) matchupsListener();
            if (leadersListener) leadersListener();
            if (picksListener) picksListener();
            if (chatsListener) chatsListener();
            if (unreadListener) unreadListener();
            dispatch(setCurrentGame(null));
        }
    }, [])

    useEffect(() => {
        if (!userProfile || !currentGame) return;
        getGameData();
    }, [userProfile, currentGame])

    useEffect(() => {
        if (userProfile && currentGame && currentToken) {
            saveNewToken(currentToken)
        }

        async function saveNewToken(token) {
            if (!token) {
                token = await getCurrentToken();
            }
            console.log('saveNewToken')
            if (!myTokens || myTokens[currentGame.gameId] !== token) {
                console.log('saving New Token')
                const res = await UseCloudFunction('saveNewToken', {
                    'email': userProfile.email,
                    'appId': userProfile.appId,
                    'newToken': currentToken,
                    'device': navigator.userAgent,
                })
                console.log(res)
                dispatch(setSavedToken(currentToken))
                dispatch(setMyTokens({...myTokens, ...{[currentGame.gameId]: currentToken}}))
            }
        }

    }, [userProfile, currentGame, currentToken])

    function getGameData() {
        getHofData();
        getMatchups();
        getLeaders();
        getChats();
        getNotifications();
        getUnread();
    }

    async function getHofData() {
        if (currentGame.hof) {
            getUniqueDocFromDb({'collection': 'hof', 'docId': currentGame.hof, 'once': true, 'callback': callback})
            function callback(data) {
                setHof(data);
            }
        }
    }

    function getMatchups() {
        getMatchupsFromDb({'gameId': currentGame.gameId, 'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            const tempList = {...matchupsRef.current};
            if (data.status === 'active') {
                tempList[data.id] = data
            } else {
                delete tempList[data.id];
            }
            matchupsRef.current = {...tempList}
            setMatchups(matchupsRef.current);
        }
        function handleListener(unsub) {
            matchupsListener = unsub;
        }
    }

    function getLeaders() {
        getUniqueDocFromDb({'collection': 'leaders', 'docId': currentGame.gameId, 'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            setLeaders(data);
            setGameProfile(data[userProfile.appId]);
        }
        function handleListener(unsub) {
            leadersListener = unsub;
        }
    }

    function getChats() {
        getQueryFromDb({'collection': 'chats', 'gameId': currentGame.gameId, 'status': 'active', 'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            chatsRef.current = {...chatsRef.current, ...{[data.id]: data}}
            setChats(chatsRef.current);
        }
        function handleListener(unsub) {
            chatsListener = unsub;
        }
    }

    function getNotifications() {
        getQueryFromDb({'collection': 'notifications', 'gameId': currentGame.gameId, 'key1': 'category', 'value1': 'general','callback': callback, 'handleListener': handleListener})
        function callback(data) {
            notificationsRef.current = {...notificationsRef.current, ...{[data.id]: data}}
            setNotifications(notificationsRef.current);
        }
        function handleListener(unsub) {
            notificationsListener = unsub;
        }
    }

    function getUnread() {
        getUniqueDocFromDb({'collection': 'unread', 'docId': `${userProfile.appId}_${currentGame.gameId}`, 'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            // delete data.status
            console.log(data);
            if (data && Object.values(data).length === 0 || !data) {
                data = null;
            }
            setUnread(data);
        }
        function handleListener(unsub) {
            unreadListener = unsub;
        }
    }
	
	
	return (
		<div className='game-page background'>
            {
                (userProfile) &&
                <>
                <div className='game-content' >
                    <Routes>
                        <Route path='/*' element={
                            <GameLanding2 
                                matchups={matchups}
                                leaders={leaders}
                                hof={hof}
                                chats={chats}
                                gameProfile={gameProfile}
                                notifications={notifications}
                                unread={unread}
                            />
                        }></Route>
                    </Routes>
                </div>
                <SideNav2 unread={unread} />
                
                <div className='user-info-col uk-visible@m'>
                    <UserInfoCol leaders={leaders} matchups={matchups} userId={userProfile.appId} />
                </div>
                <MobileNav unread={unread} />
                <SlidingUserInfo leaders={leaders} matchups={matchups} userId={userProfile.appId} />
                <NewResultsModal unread={unread ?? {}} matchups={matchups} leaders={leaders} />
                <NewMessageModal unread={unread ?? {}} notifications={notifications} />
                </>
            }
		</div>
	)
}

export default GamePage2;

// <Routes>
// <Route path='/' element={< />}></Route>
// </Routes>