import React, { useEffect, useState } from 'react'
import './matchup-user-list.styles.scss';

const MatchupUserList = ({
    matchup,
    leaders
}) => {

    const [ picks, setPicks ] = useState({});

    useEffect(() => {
        console.log(leaders);
        if (!matchup || !matchup.picks || !leaders) return;
        console.log(matchup)
        let obj = {}
        for (let p of Object.keys(matchup.picks)) {
            if (!leaders[p]) continue;
            const pick = matchup.picks[p];
            obj[p] = {
                ...pick,
                ...leaders[p]
            }
            obj[p].pickText = matchup[pick.pick];
            obj[p].fullName = `${leaders[p].fName} ${leaders[p].lName}`;
            if (matchup.winner) {
                if (pick.pick !== matchup.winner) {
                    obj[p].earnings = pick.wager * -1;
                } else {
                    obj[p].earnings = pick.wager;
                }
            }
        }
        setPicks({...obj});
    }, [matchup, leaders])

    return (
        <div className='matchup-user-list'>
            {
                (Object.values(picks).length > 0) &&
                Object.values(picks)
                .sort((a,b) => 
                    matchup.winner
                    ?
                    (
                        a.earnings < b.earnings ? 1 : -1
                    )
                    :
                    (
                        a.wager < b.wager ? 1 : -1
                    )
                )
                .map(p => {
                    console.log(p);
                    return (
                        <div
                            key={p.appId} 
                            className='g-list-item user-pick-item'>
                            <div className='name-icon'>
                                <div className='user-icon-div'>
                                    <img src={p.icon} className='user-icon' />
                                </div>
                                <div>{
                                    p.fullName.length > 20
                                    ? p.fullName.substring(0, 20) + '...'
                                    : p.fullName
                                }</div>
                            </div>
                            <div className='meta'>{p.pickText}</div>
                            <div className={
                                (matchup.winner) && 
                                ((p.earnings >= 0)
                                ? 'green' : 'red')
                            }>{
                                matchup.winner 
                                ? p.earnings > 0 
                                ? `+${p.earnings}`
                                : p.earnings
                                : p.wager
                            }</div>
                        </div>            
                    )
                })
            }
        </div>
    )
}

export default MatchupUserList